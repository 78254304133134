import React from 'react';
import './App.css';
import OpenerButton from './components/OpenerButton';


function App() {
  return (
    <OpenerButton />
  );
}

export default App;
