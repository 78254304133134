import React from 'react';


const DEFAULT_HOST = "drop-off"
const DEFAULT_APP_NAME = "harbor-opener"

const OpenerButton = () => {
  const handleClick = () => {
    const params = new URLSearchParams(window.location.search);

    const host = params.get("host") || DEFAULT_HOST;
    params.delete("host");

    const queryString = params.toString();

    const deeplinkUrl = `${DEFAULT_APP_NAME}://${host}?${queryString}`;

    window.location.href = deeplinkUrl;
  };

  return (
    <button
      onClick={handleClick}
      className="opener-btn"
    >
      Go to Harbor Opener
    </button>
  );
};

export default OpenerButton;
